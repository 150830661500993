import { FormError } from '../types'

export const enum UserTypeCode {
  ADM = 'ADM',
  ASM = 'ASM',
  SE = 'SE',
  KAM = 'KAM',
}

export type UserImport = {
  userId: number
  userCentralId: string | null
  userInternalId: string | null
  username: string
  firstname: string
  lastname: string
  companyId: number | null
  companyInternalId: string | null
  companyName: string | null
  regionId: number | null
  regionName: string | null
}

export type UserImportListReponse = {
  data: {
    users: UserImport[]
  }
}

export type User = {
  userId: number
  userCentralId: string
  userInternalId: string
  typeId: number
  typeName: string
  typeCode: UserTypeCode
  stateId: number
  stateName: string
  stateCode: string
  firstname: string
  lastname: string
  email: string
  mobile: string | null
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
  lastLoginDate: string | null
  bankAccount: string | null
  applicationVersion: string | null
  applicationVersionId: number | null
  deviceOsVersion: string | null
  deviceModelName: string | null
  isVisible: boolean | string
  userGroupId: number | null
  userGroupName: string | null
  userGroupCode: string | null
}

export type UsersResponse = {
  data: {
    users: User[]
    totalCount: number
  }
}

export type UserAddStep1Params = {
  companyId: number
  regionId: number
  firstname: string
  lastname: string
  centralId: string
  internalId: string | null
  username: string
  email: string
  mobile: string | null
  userTypeCode: UserTypeCode
  password: string | null
  generatePassword: boolean
  userGroupId: number | null
}

export type UserAddStep1Errors = {
  companyId: FormError
  regionId: FormError
  firstname: FormError
  lastname: FormError
  centralId: FormError
  internalId: FormError
  username: FormError
  email: FormError
  mobile: FormError
  password: FormError
  generatePassword: FormError
  chooseWayToGeneratePassword: FormError
}

export type UserAddStep2Errors = {
  checkedChannels: FormError
}

export type UserAddStep1Response = {
  data: {
    userId: number
    success: boolean
  }
}

export type UserAssignToMessengerParams = {
  userId: number
  channels: { id: number }[]
  groupConversations: { id: number }[]
}

export type UserEditParams = {
  userId: number
  companyId: number
  regionId: number
  firstname: string
  lastname: string
  username: string
  email: string
  mobile: string | null
  resendInvitationMail: boolean
  userGroupId: number | null
}

export type UserEditErrors = {
  companyId: FormError
  regionId: FormError
  firstname: FormError
  lastname: FormError
  username: FormError
  email: FormError
  mobile: FormError
}

export type UserChangePointsErrors = {
  accountOperation: FormError
  period: FormError
  value: FormError
  name: FormError
}

export type EditUserResponse = {
  data: {
    success: boolean
  }
}

export type UserDetails = {
  userId: number
  userCentralId: string
  userInternalId: string | null
  firstname: string
  lastname: string
  username: string
  email: string | null
  mobile: string | null
  bankAccount: string | null
  userTypeId: number | null
  userTypeName: string | null
  userTypeCode: string | null
  userStateId: number
  userStateName: string
  userStateCode: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number
  regionName: string | null
  regionShortName: string | null
  lastLoginDate: string | null
  isVisible: boolean
  userGroupId: number | null
  userGroupName: string | null
  userGroupCode: string | null
}

export type UserDetailsResponse = {
  data: UserDetails
}

export type UserChangePointsParams = {
  users: { id: number }[]
  name: string
  operationTypeId: number
  operationValue: number
  periodId: number
}

export type UserChangePointsResponse = {
  data: {
    success: boolean
  }
}

export type UserDeactivateResponse = {
  data: {
    success: boolean
  }
}

export type UserHideResponse = {
  data: {
    success: boolean
  }
}

export type UserState = {
  id: number
  code: string
  name: string
  description: string
}

export type UserStateListResponse = {
  data: {
    userStates: UserState[]
  }
}

export type UserFilter = {
  id: number
  centralId: string
  internalId: string | null
  firstname: string
  lastname: string
  email: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
}

export type UserListFilterResponse = {
  data: {
    users: UserFilter[]
  }
}

export type UserFilterByType = {
  id: number
  centralId: string
  internalId: string | null
  firstname: string
  lastname: string
  typeCode: string
  email: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
}

export type UserFilterByTypeResponse = {
  data: {
    users: UserFilterByType[]
  }
}

export type UserDataCheck = {
  firstname: string | null
  lastname: string | null
  email: string | null
}

export type UserDataCheckResponse = {
  data: UserDataCheck
}

export type UserPointsExportXlsx = {
  userId: number
  userCentralId: string
  firstname: string
  lastname: string
  companyName: string
  isActive: boolean | string
  isVisible: boolean | string
  xpValue: number
  vpValue: number
  epValue: number
}

export type UserPointsExportXlsxResponse = {
  data: {
    results: UserPointsExportXlsx[]
  }
}

export type UserType = {
  id: number
  name: string
  description: string | null
  code: string
}

export type UserTypesResponse = {
  data: {
    userTypes: UserType[]
  }
}

export type HideColumnsParametersResponse = {
  data: {
    userRoles: string[]
  }
}

export type UserGroupItem = {
  id: number
  name: string
  code: string
}

export type UserGroupListResponse = {
  data: {
    userGroups: UserGroupItem[]
  }
}

export type UserPartnerItem = {
  id: number
  centralId: string
  internalId: string | null
  firstname: string
  lastname: string
  typeCode: string
  email: string
  companyId: number | null
  companyName: string | null
  companyShortName: string | null
  regionId: number | null
  regionName: string | null
  regionShortName: string | null
}

export type UserPartnerListResponse = {
  data: {
    users: UserPartnerItem[]
  }
}
